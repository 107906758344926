import React from 'react';
import type { CSSProperties } from '@compiled/react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const replyBranchStyles = css({
	position: 'relative',
	// for the curvy border
	'&::before': {
		content: "''",
		position: 'absolute',
		left: `var(--customBeforeLeft)`,
		top: '0px',
		width: '11px',
		height: `var(--customHeight)`,
		borderLeft: `1.5px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottom: `1.5px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottomLeftRadius: '7px',
	},
});

interface WithCurvedBranchingStyleProps {
	showDefaultStyles: boolean;
	customStyles?: CSSProperties;
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	testId?: string;
	disableLeftProp?: boolean;
}

export const WithCurvedBranchingStyle = ({
	showDefaultStyles,
	children,
	customStyles,
	onClick,
	testId,
	disableLeftProp,
}: WithCurvedBranchingStyleProps) => {
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			css={[showDefaultStyles && replyBranchStyles]}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				...(() => {
					const { height, left, ...rest } = customStyles || {};
					return rest;
				})(),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				...(customStyles?.height && { '--customHeight': customStyles.height }),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				...(customStyles?.left
					? { '--customBeforeLeft': customStyles.left }
					: { '--customBeforeLeft': disableLeftProp ? '0px' : '-24px' }),
			}}
			onClick={onClick}
			data-testid={testId}
		>
			{children}
		</div>
	);
};
