import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const CreateInlineReplyMutation = gql`
	mutation CreateInlineReplyMutation($input: ReplyInlineCommentInput!, $pageId: ID!) {
		replyInlineComment(input: $input) {
			...inlineCommentFragment
			createdAt {
				value
			}
			createdAtNonLocalized
			reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
				ari
				containerAri
				reactionsCount
				reactionsSummaryForEmoji {
					id
					emojiId
					count
					reacted
				}
			}
		}
	}

	${inlineCommentFragment}
`;
