import type { ReactNode } from 'react';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import LinkIcon from '@atlaskit/icon/core/link';
import CheckCircleIcon from '@atlaskit/icon/core/success';
import VisuallyHidden from '@atlaskit/visually-hidden';

import {
	isClipboardApiSupported,
	isIEClipboardApiSupported,
	copyToClipboard,
	copyToClipboardIE,
	legacyCopyToClipboard,
} from '@confluence/comments-util';
import { CommentCreationLocation } from '@confluence/inline-comments-queries';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { i18n } from './inlineCommentsi18n';

type WrapperProps = { visible: boolean };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<WrapperProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  opacity: 0;
  transform: translate(-50%, 0);

  transition: opacity 0.2s ease, transform 0.2s ease;

${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  ${(props: WrapperProps) =>
		props.visible &&
		`
    opacity: 1;
    transform: translate(0,0);
  `};

  &:focus-within {
    opacity: 1;
    transform: translate(0, 0);
  }
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomTooltipWrapper = styled.div({
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const CustomizedTooltipTag = React.forwardRef<HTMLElement, React.PropsWithChildren<{}>>(
	({ children, ...rest }, ref) => (
		<CustomTooltipWrapper {...rest} ref={ref as any}>
			{children}
		</CustomTooltipWrapper>
	),
);

export const LINK_ICON_TEST_ID = 'link-icon';
export const CHECK_ICON_TEST_ID = 'check-icon';
export const HELPER_DIV_TEST_ID = 'legacy-clipboard-support';

const RESET_TIMEOUT = 5000;

interface Props {
	visible: boolean;
	textToCopy: string;
	children?: ReactNode;
}

export const CopyLinkButton = ({ visible, textToCopy, children }: Props) => {
	const { formatMessage } = useIntl();
	const [isCopied, setCopied] = useState(false);

	const copyWrapperRef = useRef<null | HTMLDivElement>(null);
	const cleanupTimer = useRef<NodeJS.Timeout>();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isLivePage = useIsCurrentPageLive();
	const isEditor = useIsEditorPage();

	const needRenderCopyArea = useMemo(
		() => !isClipboardApiSupported() && !isIEClipboardApiSupported(),
		[],
	);

	useEffect(() => {
		if (needRenderCopyArea) {
			copyWrapperRef.current = document.getElementById('copyWrapperRef') as HTMLDivElement;
		}
	}, [needRenderCopyArea]);

	useEffect(() => {
		if (isCopied) {
			cleanupTimer.current = setTimeout(() => setCopied(false), RESET_TIMEOUT);
		}

		return () => clearTimeout(cleanupTimer.current as any);
	}, [isCopied]);

	const handleButtonClick = async (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (isCopied) return;

		if (isClipboardApiSupported()) {
			await copyToClipboard(textToCopy);
		} else if (isIEClipboardApiSupported()) {
			await copyToClipboardIE(textToCopy);
		} else {
			await legacyCopyToClipboard(textToCopy, copyWrapperRef.current as HTMLDivElement);
		}

		const pageMode = (
			isLivePage
				? CommentCreationLocation.LIVE
				: isEditor
					? CommentCreationLocation.EDITOR
					: CommentCreationLocation.RENDERER
		).toLowerCase();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: isEditor ? 'editPageScreen' : 'viewPageScreen',
				action: 'clicked',
				actionSubject: 'copyCommentLink',
				attributes: {
					pageMode,
				},
			},
		}).fire();

		setCopied(true);
	};

	const tooltipContent = isCopied
		? formatMessage(i18n.linkCopiedTooltip)
		: formatMessage(i18n.copyLinkTooltip);

	return (
		<Wrapper visible={visible}>
			<Tooltip
				content={tooltipContent}
				position="top"
				tag={CustomizedTooltipTag}
				hideTooltipOnClick
			>
				<Button
					appearance="subtle-link"
					data-cy="inline-comment-time-link"
					iconBefore={
						!isCopied ? (
							<LinkIcon
								label={formatMessage(i18n.copyLinkTooltip)}
								testId={LINK_ICON_TEST_ID}
								spacing="spacious"
							/>
						) : (
							<CheckCircleIcon
								label={formatMessage(i18n.linkCopiedTooltip)}
								color={token('color.icon.success')}
								testId={CHECK_ICON_TEST_ID}
								spacing="spacious"
							/>
						)
					}
					onClick={handleButtonClick}
					spacing="none"
				/>
			</Tooltip>
			{needRenderCopyArea && (
				// there won't be children, but VisuallyHidden requires it
				<VisuallyHidden testId={HELPER_DIV_TEST_ID} id="copyWrapperRef">
					{children}
				</VisuallyHidden>
			)}
		</Wrapper>
	);
};
