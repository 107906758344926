import type { FC } from 'react';
import React from 'react';

import { token } from '@atlaskit/tokens';

export const HighlightLineSVG: FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="2" height="22" viewBox="0 0 2 22" fill="none">
			<path
				d="M1 1L1 21"
				stroke={token('color.background.accent.yellow.subtlest.pressed')}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};
